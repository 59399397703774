import React, { useState, useEffect, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import Stack from '@material-ui/core/Stack';
import Paper from '@material-ui/core/Paper';

export default function ButtonAppBar() {
  const [values, setValues] = useState({
    amount: 550000,
    patentquantity: 2,
  });
  const [budget, setbudget] = useState(27500);
  const [amount, setamount] = useState(550000);
  const [count, setcount] = useState(2);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
   
  };

  useEffect(() => {
    setbudget((values.amount * 0.05) + (values.amount * 0.008 * values.patentquantity));
    console.log("Value = " + values.amount.toString() + " | Budget = " + budget.toString())
    console.log(values);
    
  }, [budget, values]);





  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Patent Investigation Services
          </Typography>
          <Button color="inherit"></Button>
        </Toolbar>
      </AppBar>

      <Container>
        <Box style={{ marginTop: 30 }}>
          The law firm of Smith & Hopen provides intellectual property investigations for investors.
        </Box>
        <Paper elevation={3} style={{ padding: 12 }}>
          <Box style={{ marginTop: 30 }}>

            <InputLabel htmlFor="outlined-adornment-amount">Proposed Patent Portfolio Valuation</InputLabel>
            <OutlinedInput
              id="amount"
              value={values.amount}
              onChange={handleChange('amount')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Amount"
            />

            <InputLabel htmlFor="outlined-adornment-amount">Patent Families</InputLabel>
            <OutlinedInput
              id="patentquantity"
              value={values.patentquantity}
              onChange={handleChange('patentquantity')}
              label="Patent Families"
            />

          </Box>
          <Box style={{ marginTop: 30 }}><NumberFormat value={budget} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Box>


        </Paper>

      </Container>

    </Box>


  );
}
